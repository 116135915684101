import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import useEventListener from '@use-it/event-listener';
import { ReactComponent as BarChartIcon } from 'assets/icons/bar-chart.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as ExitFullScreenIcon } from 'assets/icons/exit-full-screen.svg';
import { ReactComponent as FullScreenIcon } from 'assets/icons/full-screen.svg';
import { ReactComponent as LineChartIcon } from 'assets/icons/line-chart.svg';
import { ReactComponent as StackedBarChartIcon } from 'assets/icons/stacked-bar-chart.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { Chart as ChartJS } from 'chart.js';
import dayjs from 'dayjs';
import _ from 'lodash';

import { Modal } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Slider from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/system';
import styled from '@mui/system/styled';
import { gridFilteredSortedRowEntriesSelector, GridRowId, useGridApiEventHandler } from '@mui/x-data-grid-pro';

import { SessionContext } from '../../auth';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { HeaderContext } from '../../layouts/main/header/context';
import LabeledNumber from '../../layouts/main/header/LabeledNumber';
import DashboardContext from '../../pages/DashboardContext';
import {
  DashboardType,
  IAggregatedStats,
  ICampaignOfferTrend,
  ICampaignOrOffer,
  IChartPoint,
  ICommonCampaignRow,
  IGrossChartPoint,
  IGrossRow,
  IIncrementalChartPoint,
  IIncrementalRow,
  ISummaryTrend,
  ITrendWithProducts
} from '../../types/global';
import { IProfile, IProfileDomain } from '../../types/users';
import { makeFormatCurrency, makeFormatPercent, useShortYearDateFormat } from '../../utils/format';
import { useQueryPersistentState, useQueryState } from '../../utils/QueryParamsContext';

import Chart, {
  campaignFields,
  differenceFields,
  fieldTitleMap,
  grossFields,
  incrementalFields,
  SelectedField
} from './Chart';
import DashboardSelect from './DashboardSelect';

function getSummaryTrend(data: ICampaignOfferTrend[], hiddenTrends: Record<string, boolean>, dashboardType: DashboardType, dayByDay: boolean, minDate: string): ISummaryTrend {
  const notHidden = data
    .filter((trend) => !hiddenTrends[trend.offer ? `OFFER___${trend.offer}` : trend.campaign]);
  const allPoints = (() => {
    if (dayByDay) {
      const longest = _.maxBy(notHidden, (x) => x.points.length);
      if (longest) {
        return Object.fromEntries(longest.points.map((x, i) => (
          [
            dayjs(minDate).add(i, 'days'),
            _.compact(notHidden.map((trend) => trend.points[i]))
          ]
        )));
      }
      return {};
    }
    return _.groupBy(
      notHidden.flatMap((trend) => trend.points),
      (point) => point.date,
    );
  })();
  const points: IChartPoint[] = _.sortBy(_.map(allPoints, (datePoints, date): IChartPoint => {
    if (dashboardType === DashboardType.Incremental) {
      const incrementalPoints = datePoints as IIncrementalChartPoint[];
      const controlPurchases = _.sum(incrementalPoints.map((point) => point.purchases[0]));
      const treatmentPurchases = _.sum(incrementalPoints.map((point) => point.purchases[1]));
      const controlOffers = _.sum(incrementalPoints.map((point) => point.offers[0]));
      const treatmentOffers = _.sum(incrementalPoints.map((point) => point.offers[1]));
      const newNetRevenue = _.sum(incrementalPoints.map((point) => point.newNetRevenue));
      const controlCarts = _.sum(incrementalPoints.map((point) => point.carts[0]));
      const treatmentCarts = _.sum(incrementalPoints.map((point) => point.carts[1]));
      const controlCartCreationRate = controlCarts / controlOffers;
      const treatmentCartCreationRate = treatmentCarts / treatmentOffers;
      const cartCreationChange = (treatmentCartCreationRate / controlCartCreationRate - 1) * 100;
      const controlPurchaseRate = controlPurchases / controlOffers;
      const treatmentPurchaseRate = treatmentPurchases / treatmentOffers;
      const purchaseRateChange = (treatmentPurchaseRate / controlPurchaseRate - 1) * 100;
      const controlPurchasedValue = _.sum(incrementalPoints.map((point) => point.purchasedValue[0]));
      const treatmentPurchasedValue = _.sum(incrementalPoints.map((point) => point.purchasedValue[1]));
      const controlAOV = controlPurchasedValue / controlPurchases;
      const treatmentAOV = treatmentPurchasedValue / treatmentPurchases;
      const grossRevenue = _.sum(incrementalPoints.map((point) => point.grossRevenue));
      const controlVisits = _.sum(incrementalPoints.map((point) => point.visits[0]));
      const treatmentVisits = _.sum(incrementalPoints.map((point) => point.visits[1]));
      const controlShoppers = _.sum(incrementalPoints.map((point) => point.shoppers[0]));
      const treatmentShoppers = _.sum(incrementalPoints.map((point) => point.shoppers[1]));
      const controlRpv = controlPurchasedValue / controlVisits;
      const treatmentRpv = grossRevenue / treatmentVisits;
      const controlRps = controlPurchasedValue / controlShoppers;
      const treatmentRps = grossRevenue / treatmentShoppers;
      return {
        date,
        purchaseRate: [controlPurchaseRate * 100, treatmentPurchaseRate * 100],
        newNetRevenue,
        cartCreationChange,
        purchaseRateChange,
        aov: [controlAOV, treatmentAOV],
        carts: [controlCarts, treatmentCarts],
        offers: [controlOffers, treatmentOffers],
        purchases: [controlPurchases, treatmentPurchases],
        purchasedValue: [controlPurchasedValue, treatmentPurchasedValue],
        grossRevenue,
        revenuePerVisit: [controlRpv, treatmentRpv],
        revenuePerShopper: [controlRps, treatmentRps],
        visits: [controlVisits, treatmentVisits],
        shoppers: [controlShoppers, treatmentShoppers],
      };
    }
    const grossPoints = datePoints as IGrossChartPoint[];
    const purchases = _.sum(grossPoints.map((point) => point.purchases));
    const purchasedValue = _.sum(grossPoints.map((point) => point.purchasedValue));
    const visits = _.sum(grossPoints.map((point) => point.visits));
    const shoppers = _.sum(grossPoints.map((point) => point.shoppers));
    const grossRevenue = _.sum(grossPoints.map((point) => point.grossRevenue));
    const carts = _.sum(grossPoints.map((point) => point.carts));
    const offers = _.sum(grossPoints.map((point) => point.offers));
    const cartCreationRate = carts / visits;
    const purchaseRate = purchases / visits;
    const aov = purchasedValue / purchases;
    const revenuePerVisit = grossRevenue / visits;
    const revenuePerShopper = grossRevenue / shoppers;
    return {
      date,
      purchaseRate: purchaseRate * 100,
      grossRevenue,
      aov,
      carts,
      offers,
      purchases,
      purchasedValue,
      cartCreationRate,
      revenuePerVisit,
      revenuePerShopper,
      visits,
      shoppers,
    };
  }), (point) => point.date);
  return { isSummary: true, points };
}

function getAggregatedStats(data: ITrendWithProducts[], hiddenTrends: Record<string, boolean>, dashboardType: DashboardType): IAggregatedStats {
  const notHiddenTrends = data.filter((trend) => (
    !hiddenTrends[trend.offer ? `OFFER___${trend.offer}` : trend.campaign]
  ));
  if (dashboardType === DashboardType.Incremental) {
    const incrementalRevenue = _.sum(
      notHiddenTrends.map((trend) => {
        const incrementalPoints = trend.points as IIncrementalChartPoint[];
        const treatmentRevenue = _.sum(incrementalPoints.map((point) => (point.aov[1] ?? 0) * (point.purchases[1] ?? 0)));
        const controlRevenue = _.sum(incrementalPoints.map((point) => (point.aov[0] ?? 0) * (point.purchases[0] ?? 0)));
        const treatmentOffers = _.sum(incrementalPoints.map((point) => point.offers[1] ?? 0));
        const controlOffers = _.sum(incrementalPoints.map((point) => point.offers[0] ?? 0));
        const estimatedNoTreatmentRevenue = (controlRevenue * (treatmentOffers / controlOffers)) || 0;
        return treatmentRevenue - estimatedNoTreatmentRevenue;
      }),
    );
    const sumPoints = (trends: ITrendWithProducts[], key: 'offers' | 'carts' | 'purchases' | 'purchasedValue', testGroup: 0 | 1) => (
      _.sum(trends.map((trend) => (
        _.sum(trend.points.map((point) => (point as IIncrementalChartPoint)[key][testGroup] ?? 0))
      )))
    );
    const grossRevenue = sumPoints(notHiddenTrends, 'purchasedValue', 1);
    const cartCreationChange = (() => {
      const ccTrends = notHiddenTrends.filter((trend) => trend.products.includes('cc'));
      const controlOffers = sumPoints(ccTrends, 'offers', 0);
      const treatmentOffers = sumPoints(ccTrends, 'offers', 1);
      const controlCarts = sumPoints(ccTrends, 'carts', 0);
      const treatmentCarts = sumPoints(ccTrends, 'carts', 1);
      if (controlCarts === 0 || controlOffers === 0 || treatmentOffers === 0) {
        return null;
      }
      const controlCreationRate = controlCarts / controlOffers;
      const treatmentCreationRate = treatmentCarts / treatmentOffers;
      return (treatmentCreationRate / controlCreationRate - 1) * 100;
    })();
    const conversionChange = (() => {
      const csTrends = notHiddenTrends.filter((trend) => trend.products.includes('cs'));
      const controlPurchases = sumPoints(csTrends, 'purchases', 0);
      const treatmentPurchases = sumPoints(csTrends, 'purchases', 1);
      const controlOffers = sumPoints(csTrends, 'offers', 0);
      const treatmentOffers = sumPoints(csTrends, 'offers', 1);
      if (controlPurchases === 0 || controlOffers === 0 || treatmentOffers === 0) {
        return null;
      }
      const controlPurchaseRate = controlPurchases / controlOffers;
      const treatmentPurchaseRate = treatmentPurchases / treatmentOffers;
      return (treatmentPurchaseRate / controlPurchaseRate - 1) * 100;
    })();
    return { incrementalRevenue, cartCreationChange, conversionChange, grossRevenue };
  }
  const sumPoints = (trends: ITrendWithProducts[], key: 'visits' | 'shoppers' | 'purchasedValue') => (
    _.sum(trends.map((trend) => (
      _.sum(trend.points.map((point) => (point as IGrossChartPoint)[key] ?? 0))
    )))
  );
  const grossRevenue = sumPoints(notHiddenTrends, 'purchasedValue');
  const shoppers = sumPoints(notHiddenTrends, 'shoppers');
  const visits = sumPoints(notHiddenTrends, 'visits');
  const revenuePerShopper = grossRevenue / shoppers;
  const revenuePerVisit = grossRevenue / visits;
  return { grossRevenue, revenuePerShopper, revenuePerVisit };
}

function filterChartData(
  data: ITrendWithProducts[],
  minDate: string,
  dateRange: [number, number],
  dayByDay: boolean,
  selectedOffers: ICommonCampaignRow[] | null,
  filteredOffers: ICampaignOrOffer[],
  selectedField: SelectedField,
  rows: {
    id: GridRowId,
    model: IIncrementalRow | IGrossRow,
  }[],
): ITrendWithProducts[] {
  const startDate = dayjs(minDate).add(dateRange[0], 'days');
  const endDate = dayjs(minDate).add(dateRange[1], 'days');
  return _.sortBy(data.filter((trend) => {
    if (!rows.find(({ model }) => model.campaign === trend.campaign && model.offer === trend.offer)) {
      return false;
    }
    if (!selectedOffers) {
      return !('offer' in trend) || filteredOffers.find((x) => x.offer === trend.offer);
    }
    return selectedOffers.find((x) => (
      'offer' in trend
        ? x.offer === trend.offer
        : (!('offer' in x) && x.campaign === trend.campaign)
    )) !== undefined;
  }).map(({ points, ...rest }) => ({
    ...rest,
    points: points.filter((point) => {
      if (dayByDay) {
        const trendStart = dayjs(points.find((x) => (x as any)[selectedField] !== null)?.date);
        const relativeStart = dayjs(trendStart).add(dateRange[0], 'days');
        const relativeEnd = dayjs(trendStart).add(dateRange[1], 'days');
        return dayjs(point.date).isSameOrAfter(relativeStart) && dayjs(point.date).isSameOrBefore(relativeEnd);
      }
      return dayjs(point.date).isSameOrAfter(startDate) && dayjs(point.date).isSameOrBefore(endDate);
    }),
  })).filter((x) => (
    x.points.length > 0
  )), (x) => x.points[0].date);
}

const AGGREGATE_DATAPOINTS_THRESHOLD = 200;
function aggregateChartData(data: ITrendWithProducts[], dashboardType: DashboardType):
  {
    trends: ITrendWithProducts[],
    dayStepSize: number,
  } {
  const allPointsCount = data.flatMap((x) => x.points).length;
  if (allPointsCount <= 1) {
    return { trends: data, dayStepSize: 1 };
  }
  if (allPointsCount < AGGREGATE_DATAPOINTS_THRESHOLD) {
    return { trends: data, dayStepSize: 1 };
  }
  const trends = data.map((trend) => {
    if (trend.points.length === 0) {
      return trend;
    }
    const grouped = _.groupBy(trend.points, (x) => dayjs(x.date).startOf('week').valueOf());
    const aggregated = Object.values(grouped).map((group): IChartPoint => {
      const date = dayjs(group[0].date).startOf('week').format('YYYY-MM-DD');
      const sumSingular = (key: keyof IChartPoint) =>
        _.sum(group.map((point) => point[key] ?? 0));
      const grossRevenue = sumSingular('grossRevenue');
      if (dashboardType === DashboardType.Incremental) {
        const incrementalPoints = group as IIncrementalChartPoint[];
        const treatmentRevenue = _.sum(incrementalPoints.map((point) => (point.aov[1] ?? 0) * (point.purchases[1] ?? 0)));
        const controlRevenue = _.sum(incrementalPoints.map((point) => (point.aov[0] ?? 0) * (point.purchases[0] ?? 0)));
        const sumPoints = (key: 'offers' | 'carts' | 'purchases' | 'purchasedValue', testGroup: 0 | 1) =>
          _.sum(incrementalPoints.map((point) => point[key][testGroup] ?? 0));
        const controlOffers = sumPoints('offers', 0);
        const treatmentOffers = sumPoints('offers', 1);
        const estimatedNoTreatmentRevenue = (controlRevenue * (treatmentOffers / controlOffers)) || 0;
        const incrementalRevenue = treatmentRevenue - estimatedNoTreatmentRevenue;
        const controlCarts = sumPoints('carts', 0);
        const treatmentCarts = sumPoints('carts', 1);
        const cartCreationChange = (() => {
          if (controlCarts === 0 || controlOffers === 0 || treatmentOffers === 0) {
            return undefined;
          }
          const controlCreationRate = controlCarts / controlOffers;
          const treatmentCreationRate = treatmentCarts / treatmentOffers;
          return (treatmentCreationRate / controlCreationRate - 1) * 100;
        })();
        const controlPurchases = sumPoints('purchases', 0);
        const treatmentPurchases = sumPoints('purchases', 1);
        const controlPurchaseRate = !controlOffers ? undefined : controlPurchases / controlOffers;
        const treatmentPurchaseRate = !treatmentOffers ? undefined : treatmentPurchases / treatmentOffers;
        const purchaseRateChange = (!treatmentPurchaseRate || !controlPurchaseRate) ? undefined : (treatmentPurchaseRate / controlPurchaseRate - 1) * 100;
        const controlPurchasedValue = sumPoints('purchasedValue', 0);
        const treatmentPurchasedValue = sumPoints('purchasedValue', 1);
        const controlAOV = !controlPurchases ? undefined : controlPurchasedValue / controlPurchases;
        const treatmentAOV = !treatmentPurchases ? undefined : treatmentPurchasedValue / treatmentPurchases;
        const aovDiff = (controlAOV != null && treatmentAOV != null) ? treatmentAOV - controlAOV : undefined;
        const controlVisits = _.sum(incrementalPoints.map((point) => point.visits[0]));
        const treatmentVisits = _.sum(incrementalPoints.map((point) => point.visits[1]));
        const controlShoppers = _.sum(incrementalPoints.map((point) => point.shoppers[0]));
        const treatmentShoppers = _.sum(incrementalPoints.map((point) => point.shoppers[1]));
        const controlRpv = controlPurchasedValue / controlVisits;
        const treatmentRpv = grossRevenue / treatmentVisits;
        const controlRps = controlPurchasedValue / controlShoppers;
        const treatmentRps = grossRevenue / treatmentShoppers;
        return {
          date,
          purchaseRate: [controlPurchaseRate, treatmentPurchaseRate],
          newNetRevenue: incrementalRevenue,
          cartCreationChange,
          purchaseRateChange,
          aov: [controlAOV, treatmentAOV],
          aovDiff,
          offers: [controlOffers, treatmentOffers],
          carts: [controlCarts, treatmentCarts],
          purchases: [controlPurchases, treatmentPurchases],
          purchasedValue: [controlPurchasedValue, treatmentPurchasedValue],
          grossRevenue,
          visits: [controlVisits, treatmentVisits],
          shoppers: [controlShoppers, treatmentShoppers],
          revenuePerVisit: [controlRpv, treatmentRpv],
          revenuePerShopper: [controlRps, treatmentRps],
        };
      }
      const purchases = sumSingular('purchases');
      const offers = sumSingular('offers');
      const purchaseRate = offers === 0 ? 0 : purchases / offers;
      const purchasedValue = sumSingular('purchasedValue');
      const aov = purchasedValue / purchases;
      const carts = sumSingular('carts');
      const cartCreationRate = carts / offers;
      const visits = sumSingular('visits');
      const shoppers = sumSingular('shoppers');
      const revenuePerVisit = grossRevenue / visits;
      const revenuePerShopper = grossRevenue / shoppers;
      return {
        date,
        purchaseRate,
        cartCreationRate,
        aov,
        purchases,
        offers,
        purchasedValue,
        carts,
        grossRevenue,
        visits,
        shoppers,
        revenuePerVisit,
        revenuePerShopper,
      };
    });
    return {
      ...trend,
      points: aggregated,
    };
  });
  return { trends, dayStepSize: 7 };
}

const TopPane = styled(Box)(({ theme }) =>( {
  height: '48px',
  padding: '0 12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  position: 'relative',
  [theme.breakpoints.up('lg')]: {
    '&:first-of-type::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      right: 0,
      top: '10%',
      height: '80%',
      width: '1px',
      backgroundColor: theme.palette.secondary.light,
    },
  },
  [theme.breakpoints.down('lg')]: {
    boxShadow: 'none',
    width: '100%',
    marginRight: 0,
    height: '40px',
  },
}));

const StyledControlLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: '2px 16px 2px 0',
  '& .MuiFormControlLabel-label': {
    fontSize: '10px',
    lineHeight: '12px',
    fontWeight: 800,
    fontVariant: 'all-small-caps',
    color: theme.palette.primary.main,
    whiteSpace: 'pre',
  },
}));

interface GridRow {
  id: GridRowId,
  model: IIncrementalRow | IGrossRow,
}

interface ChartContainerProps {
  selectedOffers: ICommonCampaignRow[] | null;
  minDate: string;
  maxDate: string;
  filteredOffers: ICampaignOrOffer[];
  chartData: ITrendWithProducts[];
  setAggregatedStats: React.Dispatch<React.SetStateAction<IAggregatedStats | null>>;
  chartRef: React.RefObject<ChartJS>;
  dashboardType: DashboardType;
  performanceChartData?: { x: string, y: number }[];
  campaignChartData?: {
    field: SelectedField;
    data: { x: string, y: number }[];
  }[];
  isExec?: boolean;
  gridRows?: GridRow[];
}

enum ChartType { Line, Bar, StackedBar, Difference }

function ChartContainer(
  {
    selectedOffers, minDate, maxDate, filteredOffers, chartData, setAggregatedStats, chartRef: pdfChartRef,
    dashboardType, performanceChartData, isExec, gridRows, campaignChartData,
  }: ChartContainerProps,
) {
  const theme = useTheme();
  const { profile } = useContext(SessionContext) as { profile: IProfile };
  const formatCurrency = makeFormatCurrency(profile);
  const formatCurrency2 = makeFormatCurrency(profile, 2);
  const formatPercent = makeFormatPercent(profile);
  const shortYearFormat = useShortYearDateFormat();
  const outerRangeDiff = dayjs(maxDate).diff(minDate, 'days');
  const [selectedField, setSelectedField] =
    useQueryPersistentState<SelectedField>('chart-selected-field', 'purchaseRate');
  const [dateRange, setDateRange] = useQueryState<[number, number]>('chart-date-range', [0, outerRangeDiff]);
  const [dayByDay, setDayByDay] = useQueryState('day-by-day', false);
  const [summaryTrend, setSummaryTrend] = useQueryState('summary-trend', false);
  const isTuple = dashboardType === DashboardType.Incremental && (
    selectedField === 'purchaseRate' || selectedField === 'aov'
    || selectedField === 'revenuePerShopper' || selectedField === 'revenuePerVisit'
  );
  const [showControl, setShowControl] = useQueryState('show-control', isTuple);
  const [showTreatment, setShowTreatment] = useQueryState('show-treatment', isTuple);
  const isPerformance = selectedField === 'performance';
  useUpdateEffect(() => {
    if (isTuple) {
      setShowControl(true);
      setShowTreatment(true);
    } else {
      setShowControl(false);
      setShowTreatment(false);
    }
  }, [isTuple]);
  const [isBar, setIsBar] = useQueryPersistentState('chart-is-bar', false);
  const [isStacked, setIsStacked] = useQueryPersistentState('chart-is-stacked', false);
  const [isDifference, setIsDifference] = useQueryPersistentState('chart-is-difference', false);
  useEffect(() => {
    setIsBar(true);
    setIsStacked(false);
    setIsDifference(false);
  }, [isExec]);
  const chartType = (() => {
    if (isBar) {
      if (isStacked) {
        return ChartType.StackedBar;
      }
      if (isDifference) {
        return ChartType.Difference;
      }
      return ChartType.Bar;
    }
    return ChartType.Line;
  })();
  useEffect(() => {
    if (chartType === ChartType.Difference && !differenceFields.includes(selectedField)) {
      setIsDifference(false);
    }
  }, [chartType, selectedField]);
  const [isFullscreen, setIsFullscreen] = useState(false);
  useLayoutEffect(() => {
    if (isFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  }, [isFullscreen]);
  useEventListener('fullscreenchange', () => {
    if (!document.fullscreenElement) {
      setIsFullscreen(false);
    }
  }, document.documentElement);
  const [hiddenTrends, setHiddenTrends] = useQueryState<Record<string, boolean>>('hidden-trends', {});
  useUpdateEffect(() => {
    setHiddenTrends({});
  }, [chartData]);
  const { apiRef } = useContext(DashboardContext);
  const [rows, setRows] = useState<GridRow[]>(gridRows ?? []);
  if (apiRef?.current) {
    useGridApiEventHandler(apiRef, 'stateChange', () => {
      setRows(gridFilteredSortedRowEntriesSelector(apiRef) as any);
    });
  }
  const filteredData = useMemo(
    () => filterChartData(chartData, minDate, dateRange, dayByDay, selectedOffers, filteredOffers, selectedField, rows),
    [chartData, minDate, dateRange, dayByDay, selectedOffers, filteredOffers, selectedField, rows],
  );
  const { trends: aggregatedData, dayStepSize } = aggregateChartData(filteredData, dashboardType);
  const summaryTrendData = getSummaryTrend(aggregatedData, hiddenTrends, dashboardType, dayByDay, minDate);
  const newChartData = (() => {
    if (isDifference) {
      return [summaryTrendData];
    }
    return [
      ...aggregatedData,
      ...(summaryTrend ? [summaryTrendData] : []),
    ];
  })();
  const maxDiff = (() => {
    if (dayByDay) {
      const allDates = chartData.flatMap((x) => x.points.map((y) => dayjs(y.date).valueOf()));
      return dayjs(_.max(allDates)).diff(_.min(allDates), 'days');
    }
    return outerRangeDiff;
  })();
  useUpdateEffect(() => {
    if (maxDiff) {
      setDateRange([0, maxDiff]);
    }
  }, [minDate, maxDate, maxDiff, dayByDay]);
  const aggregatedStats = useMemo(
    () => getAggregatedStats(aggregatedData, hiddenTrends, dashboardType),
    [aggregatedData, hiddenTrends],
  );
  useEffect(() => {
    setAggregatedStats(aggregatedStats);
  }, [aggregatedStats]);
  const chartProps = {
    data: newChartData,
    performanceData: performanceChartData,
    campaignData: campaignChartData,
    selectedField,
    dateRange,
    showControl,
    showTreatment,
    dayByDay,
    isBar,
    isStacked: isBar && isStacked,
    isDifference: isBar && isDifference,
    hiddenTrends,
    setHiddenTrends,
    dashboardType,
    dayStepSize,
  };
  const chartRef = useRef<ChartJS>(null);
  const typeFields = (() => {
    if (campaignChartData) {
      return campaignFields;
    }
    return (dashboardType === DashboardType.Incremental ? incrementalFields : grossFields);
  })();
  useEffect(() => {
    if (!typeFields.includes(selectedField)) {
      setSelectedField(typeFields[0]);
    }
  }, [dashboardType, selectedField]);
  const { domainId } = useContext(HeaderContext);
  const { name: domainName } = profile.organizations
    .flatMap((x) => x.domains)
    .find((x) => x.id === domainId) as IProfileDomain;
  const formatSliderDate = (x: number) => (
    dayByDay ? `Day ${x + 1}` : dayjs(minDate).add(x, 'days').format(shortYearFormat)
  );
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const containerStyles = (() => {
    if (isFullscreen) {
      return {
        height: '100%',
        background: theme.palette.secondary.lighter,
        padding: '8px',
      };
    }
    if (isExec) {
      return { margin: '20px 0' };
    }
    return {
      width: '100vw',
      height: '348px',
      margin: '0 -24px 28px',
    };
  })();
  const content = (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        ...containerStyles,
        [theme.breakpoints.down('lg')]: {
          margin: '0 -16px 8px',
          width: '100vw',
          paddingRight: '0',
          position: 'relative',
          height: '380px',
        },
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          width: { xs: '100%', lg: 'calc(100% - 34px)' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: { xs: 'wrap', lg: 'nowrap' },
            boxShadow: '0px 2px 5px rgba(142, 142, 142, 0.2), 0px 2px 10px rgba(142, 142, 142, 0.14), 0px 5px 14px rgba(142, 142, 142, 0.12)',
          }}
        >
          <TopPane
            sx={{
              flexBasis: '504px',
              ...(isExec ? {
                flexBasis: 'auto',
                marginRight: 'auto',
                [theme.breakpoints.up('lg')]: {
                  '&:first-of-type::after': {
                    display: 'none',
                  },
                },
              } : {}),
          }}
          >
            <DashboardSelect
              select
              inputProps={{ 'aria-label': 'Chart field' }}
              value={selectedField}
              onChange={({ target }) => {
                setSelectedField(target.value as SelectedField);
                if (target.value === 'performance') {
                  setDateRange([0, outerRangeDiff]);
                  setDayByDay(false);
                  setSummaryTrend(false);
                  setIsBar(true);
                  setIsStacked(false);
                }
              }}
              sx={{
                flexBasis: '220px',
                marginRight: '12px',
                [theme.breakpoints.down('lg')]: {
                  flexBasis: 'auto',
                  flexGrow: 1,
                },
              }}
            >
              {Object.entries(fieldTitleMap).filter(([value]: any) => (
                typeFields.includes(value)
              )).map(([value, label]) => (
                <MenuItem
                  key={value}
                  value={value}
                >
                  {label}
                </MenuItem>
              ))}
            </DashboardSelect>
            {!isExec && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  height: '100%',
                  flexBasis: '200px',
                  [theme.breakpoints.down('lg')]: {
                    flexBasis: '80px',
                    margin: '0 14px',
                    flexGrow: 1,
                    flexShrink: 0,
                  },
                }}
              >
                <Box
                  sx={{
                    fontSize: '14px',
                    color: '#4559A7',
                    fontVariant: 'all-small-caps',
                    fontWeight: 800,
                    marginBottom: '-14px',
                    [theme.breakpoints.down('lg')]: {
                      display: 'none',
                    },
                  }}
                >
                  {dateRange && `${formatSliderDate(dateRange[0])} - ${formatSliderDate(dateRange[1])}`}
                </Box>
                <Slider
                  getAriaLabel={() => 'Chart date range'}
                  value={dateRange}
                  onChange={(event, newValue, activeThumb) => {
                    if (!Array.isArray(newValue)) {
                      return;
                    }
                    const minDistance = Math.min(0, dayjs(maxDate).diff(minDate, 'days'));
                    if (newValue[1] - newValue[0] < minDistance) {
                      if (activeThumb === 0) {
                        const clamped = Math.min(newValue[0], maxDiff - minDistance);
                        setDateRange([clamped, clamped + minDistance]);
                      } else {
                        const clamped = Math.max(newValue[1], minDistance);
                        setDateRange([clamped - minDistance, clamped]);
                      }
                    } else {
                      setDateRange(newValue as [number, number]);
                    }
                  }}
                  valueLabelDisplay={isDesktop ? 'off' : 'auto'}
                  valueLabelFormat={formatSliderDate}
                  min={0}
                  max={maxDiff}
                  sx={{
                    [theme.breakpoints.down('lg')]: {
                      marginTop: '4px',
                    },
                  }}
                  disabled={isPerformance}
                />
              </Box>
            )}
          </TopPane>
          {!isExec && (
            <>
              <TopPane sx={{ display: { xs: 'none', lg: 'flex' } }}>
                <StyledControlLabel
                  control={
                    <Checkbox
                      onChange={({ target }) => setDayByDay(target.checked)}
                    />
                  }
                  label={'Day-by-day\ncomparison'}
                  checked={dayByDay}
                  disabled={isPerformance}
                />
                <StyledControlLabel
                  control={
                    <Checkbox
                      onChange={({ target }) => setSummaryTrend(target.checked)}
                    />
                  }
                  label={'Summary\ntrend'}
                  checked={summaryTrend}
                  disabled={isPerformance}
                />
                {dashboardType === DashboardType.Incremental && (
                  <FormGroup sx={{ width: 80 }}>
                    <StyledControlLabel
                      control={
                        <Checkbox
                          onChange={({ target }) => {
                            setShowControl(target.checked);
                            if (!target.checked && !showTreatment) {
                              setShowTreatment(true);
                            }
                          }}
                          disabled={!isTuple}
                        />
                      }
                      label="Control"
                      checked={showControl}
                    />
                    <StyledControlLabel
                      control={
                        <Checkbox
                          onChange={({ target }) => {
                            setShowTreatment(target.checked);
                            if (!target.checked && !showControl) {
                              setShowControl(true);
                            }
                          }}
                          disabled={!isTuple}
                        />
                      }
                      label="Treatment"
                      checked={showTreatment}
                    />
                  </FormGroup>
                )}
              </TopPane>
              <TopPane
                sx={{
                  color: theme.palette.primary.main,
                  flexBasis: { xs: '100%', lg: 'initial' },
                  order: { xs: -1, lg: 'initial' },
                  marginLeft: 'auto',
                  [theme.breakpoints.down('lg')]: {
                  justifyContent: 'flex-start',
                  gap: '20px',
                },
                }}
              >
                {'incrementalRevenue' in aggregatedStats ? (
                  <>
                    {selectedField === 'grossRevenue' && (
                      <LabeledNumber
                        width={110}
                        number={formatCurrency({ value: aggregatedStats.grossRevenue })}
                        label="gross revenue"
                      />
                    )}
                    <Tooltip title="New revenue generated by Metrical.">
                      <LabeledNumber
                        width={110}
                        number={formatCurrency({ value: aggregatedStats.incrementalRevenue })}
                        label="net new revenue"
                      />
                    </Tooltip>
                    <Tooltip title="Additional carts created when using Metrical.">
                      <LabeledNumber
                        width={80}
                        number={formatPercent({ value: aggregatedStats.cartCreationChange }, { sign: true })}
                        label="carts created"
                      />
                    </Tooltip>
                    <Tooltip title="Additional carts saved when using Metrical.">
                      <LabeledNumber
                        width={70}
                        number={formatPercent({ value: aggregatedStats.conversionChange }, { sign: true })}
                        label="carts saved"
                      />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <LabeledNumber
                      width={110}
                      number={formatCurrency({ value: aggregatedStats.grossRevenue })}
                      label="gross revenue"
                    />
                    <Tooltip title="Revenue per visit">
                      <LabeledNumber
                        width={70}
                        number={formatCurrency2({ value: aggregatedStats.revenuePerVisit })}
                        label="rev per visit"
                      />
                    </Tooltip>
                    <Tooltip title="Revenue per shopper">
                      <LabeledNumber
                        width={80}
                        number={formatCurrency2({ value: aggregatedStats.revenuePerShopper })}
                        label="rev per shopper"
                      />
                    </Tooltip>
                  </>
                )}
              </TopPane>
            </>
          )}
          <TopPane
            sx={{
                [theme.breakpoints.down('lg')]: {
                  position: 'absolute',
                  top: '81px',
                  right: '12px',
                  marginTop: 0,
                  boxShadow: 'none',
                  zIndex: 10,
                  justifyContent: 'flex-end',
                  padding: 0,
                },
            }}
          >
            {!isExec && (
              <>
                {(isBar && isStacked && newChartData.length < 2) && (
                  <Tooltip title="Stacked Bar Chart requires at least two series">
                    <Box component={WarningIcon} sx={{ margin: '0 4px', flexShrink: 0 }} />
                  </Tooltip>
                )}
                <DashboardSelect
                  select
                  inputProps={{ 'aria-label': 'Chart type' }}
                  value={chartType}
                  disabled={isPerformance}
                  onChange={({ target }) => {
                    const value = target.value as unknown as ChartType;
                    if (value === ChartType.Line) {
                      setIsBar(false);
                    } else if (value === ChartType.Bar) {
                      setIsBar(true);
                      setIsStacked(false);
                      setIsDifference(false);
                    } else if (value === ChartType.StackedBar) {
                      setIsBar(true);
                      setIsStacked(true);
                      setIsDifference(false);
                    } else if (value === ChartType.Difference) {
                      setIsBar(true);
                      setIsStacked(false);
                      setIsDifference(true);
                    }
                  }}
                  sx={{
                    width: '75px',
                    marginRight: '8px',
                    '& .MuiSelect-select svg': {
                      verticalAlign: 'middle'
                    },
                    [theme.breakpoints.down('lg')]: {
                      '& > .Mui-disabled': {
                        display: 'none',
                      },
                    },
                  }}
                  SelectProps={{
                    renderValue: (value) => {
                      if (value === ChartType.Line) {
                        return <LineChartIcon />;
                      }
                      if (value === ChartType.Bar) {
                        return <BarChartIcon />;
                      }
                      if (value === ChartType.Difference) {
                        return <BarChartIcon />;
                      }
                      return <StackedBarChartIcon />;
                    },
                    MenuProps: {
                      sx: {
                        '& svg': {
                          marginRight: '16px',
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value={ChartType.Line}>
                    <LineChartIcon />
                    Line Chart
                  </MenuItem>
                  <MenuItem value={ChartType.Bar}>
                    <BarChartIcon />
                    Bar Chart
                  </MenuItem>
                  <MenuItem value={ChartType.StackedBar}>
                    <StackedBarChartIcon />
                    Stacked Bar Chart
                  </MenuItem>
                  <MenuItem value={ChartType.Difference} disabled={!differenceFields.includes(selectedField)}>
                    <BarChartIcon />
                    Difference Chart
                  </MenuItem>
                </DashboardSelect>
              </>
            )}
            <IconButton
              size="small"
              onClick={() => {
                const chart = chartRef.current as ChartJS;
                chart.options.devicePixelRatio = 4;
                chart.resize(chart.width, chart.height);
                const a = document.createElement('a');
                a.href = chart.toBase64Image();
                const now = dayjs();
                const startDate = dayByDay ? dayjs(minDate) : dayjs(minDate).add(dateRange[0], 'days');
                const endDate = dayByDay ? dayjs(maxDate) : dayjs(minDate).add(dateRange[1], 'days');
                const formattedRange = `${startDate.format('YYYYMMDD')}-${endDate.format('YYYYMMDD')}`;
                a.download = `${domainName} - ${formattedRange} - ${now.format('YYYYMMDD_HHmm')}.png`;
                a.click();
                chart.options.devicePixelRatio = window.devicePixelRatio;
                chart.resize(chart.width, chart.height);
              }}
              aria-label="Download"
              sx={{ display: { xs: 'none', lg: 'inline-flex' } }}
            >
              <DownloadIcon />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => setIsFullscreen(!isFullscreen)}
              aria-label="Toggle fullscreen"
              sx={{ display: { xs: 'none', lg: 'inline-flex' } }}
            >
              {isFullscreen ? <ExitFullScreenIcon /> : <FullScreenIcon />}
            </IconButton>
          </TopPane>
        </Box>
        <Box
          sx={{
            flex: 1,
            backgroundColor: 'white',
            filter: 'drop-shadow(2px 5px 16px rgba(0, 0, 0, 0.1))',
          }}
        >
          <Chart ref={chartRef} {...chartProps} />
        </Box>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          left: '-9999px',
          top: '-9999px',
          visibility: 'hidden',
          width: '600px',
          height: '400px',
        }}
      >
        <Chart ref={pdfChartRef} {...chartProps} />
      </Box>
    </Box>
  );
  if (isFullscreen) {
    return (
      <Modal open>
        {content}
      </Modal>
    );
  }
  return content;
}

export default React.memo(ChartContainer);
