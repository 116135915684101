import React, { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  defaults,
  Interaction,
  Legend,
  LegendItem,
  LinearScale,
  LineElement,
  PointElement,
  Scale,
  TimeScale,
  Title,
  Tooltip,
  TooltipItem,
} from 'chart.js';
import { _limitValue, isArray, toDegrees, toFont, toPadding, toRadians } from 'chart.js/helpers';
import dayjs from 'dayjs';
import _ from 'lodash';

import { Backdrop, Portal } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/system';

import 'chartjs-adapter-dayjs-4';

import { SessionContext } from '../../auth';
import DashboardContext, { TrendColor } from '../../pages/DashboardContext';
import { DashboardType, IChartPoint, IChartTrend, IIncrementalChartPoint } from '../../types/global';
import { IProfile } from '../../types/users';
import { makeFormatCurrency, makeFormatPercent, useNoYearDateFormat } from '../../utils/format';

import customIndex from './customIndex';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
);

ChartJS.defaults.font.family = 'SoleilTnum';

export type CampaignField = 'purchaseRate' | 'newNetRevenue' | 'cartCreationChange' | 'purchaseRateChange' | 'aov'
   | 'grossRevenue' | 'revenuePerVisit' | 'revenuePerShopper' | 'cartCreationRate';
export type SelectedField = CampaignField | 'aovDiff' | 'performance';

const commonFields: CampaignField[] = ['purchaseRate', 'aov', 'grossRevenue', 'revenuePerVisit', 'revenuePerShopper'];
export const campaignFields: CampaignField[] = [...commonFields, 'cartCreationRate', 'newNetRevenue'];
export const incrementalFields: SelectedField[] = [
  ...commonFields, 'newNetRevenue', 'cartCreationChange', 'purchaseRateChange', 'performance', 'aovDiff',
];
export const grossFields: SelectedField[] = [...commonFields, 'cartCreationRate', 'performance'];
export const differenceFields: SelectedField[] = ['grossRevenue', 'newNetRevenue'];

export const fieldTitleMap = {
  aov: 'AOV',
  aovDiff: 'AOV diff',
  performance: 'Campaign Performance',
  cartCreationChange: 'Cart Creation % Change',
  cartCreationRate: 'Cart Creation Rate',
  grossRevenue: 'Gross Revenue',
  newNetRevenue: 'Net New Revenue',
  purchaseRateChange: 'Conversion Rate % Change',
  purchaseRate: 'Conversion Rate',
  revenuePerShopper: 'Revenue per Shopper',
  revenuePerVisit: 'Revenue per Visit',
};

const INCREASE_COLOR = '#4f81bd';
const DECREASE_COLOR = '#c0504d';

declare module 'chart.js/dist/types/index' {
  interface InteractionModeMap {
    testGroup: InteractionModeFunction;
    customIndex: InteractionModeFunction;
  }
  interface TooltipPositionerMap {
    barPositioner: TooltipPositionerFunction<any>;
  }
}

Interaction.modes.testGroup = (chart, e, options, useFinalPosition) => {
  const pointItems = Interaction.modes.point(chart, e, options, useFinalPosition);
  const xItems = Interaction.modes.x(chart, e, options, useFinalPosition);
  return _.sortBy(_.uniqBy(pointItems.flatMap((pointItem) => {
    const pointMeta = chart.getDatasetMeta(pointItem.datasetIndex);
    return [
      pointItem,
      ...xItems.filter((xItem) => {
        const xMeta = chart.getDatasetMeta(xItem.datasetIndex);
        return pointMeta.label.replace(/ \(.+\)/, '') === xMeta.label.replace(/ \(.+\)/, '');
      })
    ];
  }), (x) => x.element), (x) => chart.getDatasetMeta(x.datasetIndex).label);
};
Interaction.modes.customIndex = customIndex;

Tooltip.positioners.barPositioner = function barPositioner(elements) {
  const padding = 20;
  // eslint-disable-next-line no-underscore-dangle
  const gridLineItems = (this.chart.scales.x as any)._gridLineItems;
  if (gridLineItems && elements.length > 0) {
    const element = gridLineItems[elements[0].index + 1];
    if (element) {
      return {
        x: element.tx1 + padding,
        y: element.ty1,
      };
    }
    return false;
  }
  return false;
};

ChartJS.defaults.font.family = 'Soleil';

// From https://stackoverflow.com/a/47288427/3853934
const createPattern = (color: string) => {
  const patternCanvas = document.createElement('canvas');
  const pctx = patternCanvas.getContext('2d', { antialias: true }) as CanvasRenderingContext2D;

  const CANVAS_SIDE_LENGTH = 8;
  const WIDTH = CANVAS_SIDE_LENGTH;
  const HEIGHT = CANVAS_SIDE_LENGTH;
  const DIVISIONS = 4;

  patternCanvas.width = WIDTH;
  patternCanvas.height = HEIGHT;
  pctx.fillStyle = color;

  // Top line
  pctx.beginPath();
  pctx.moveTo(0, HEIGHT * (1 / DIVISIONS));
  pctx.lineTo(WIDTH * (1 / DIVISIONS), 0);
  pctx.lineTo(0, 0);
  pctx.lineTo(0, HEIGHT * (1 / DIVISIONS));
  pctx.fill();

  // Middle line
  pctx.beginPath();
  pctx.moveTo(WIDTH, HEIGHT * (1 / DIVISIONS));
  pctx.lineTo(WIDTH * (1 / DIVISIONS), HEIGHT);
  pctx.lineTo(0, HEIGHT);
  pctx.lineTo(0, HEIGHT * ((DIVISIONS - 1) / DIVISIONS));
  pctx.lineTo(WIDTH * ((DIVISIONS - 1) / DIVISIONS), 0);
  pctx.lineTo(WIDTH, 0);
  pctx.lineTo(WIDTH, HEIGHT * (1 / DIVISIONS));
  pctx.fill();

  // Bottom line
  pctx.beginPath();
  pctx.moveTo(WIDTH, HEIGHT * ((DIVISIONS - 1) / DIVISIONS));
  pctx.lineTo(WIDTH * ((DIVISIONS - 1) / DIVISIONS), HEIGHT);
  pctx.lineTo(WIDTH, HEIGHT);
  pctx.lineTo(WIDTH, HEIGHT * ((DIVISIONS - 1) / DIVISIONS));
  pctx.fill();

  return pctx.createPattern(patternCanvas, 'repeat') as CanvasPattern;
};

const getPoint = ({ dataset: { label }, raw }: TooltipItem<any>, data: IChartTrend[], requiredFields: string[] = []) => {
  const date = (raw as { x: string }).x;
  const baseLabel = (label || '').replace(/ \((?:treatment|control)\)$/, '');
  const trend = data.find((x) => 'campaign' in x && (x.campaign === baseLabel || x.offer === baseLabel));
  return {
    point: trend?.points.find((x): x is IIncrementalChartPoint => (
      x.date === date && requiredFields.every((field) => field in x)
    )),
    baseLabel,
  };
};

interface ChartProps {
  data: IChartTrend[];
  selectedField: SelectedField;
  dateRange: [number, number];
  showControl: boolean;
  showTreatment: boolean;
  dayByDay: boolean;
  isBar: boolean;
  isStacked: boolean;
  isDifference: boolean;
  hiddenTrends: Record<string, boolean>;
  setHiddenTrends: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  dashboardType: DashboardType;
  dayStepSize: number;
  performanceData?: { x: string, y: number }[];
  campaignData?: {
    field: SelectedField;
    data: { x: string, y: number }[];
  }[];
}


const getTicksLimit = (ticksLength: any, maxTicksLimit: any) =>
  Math.min(maxTicksLimit || ticksLength, ticksLength);

function getTickMarkLength(options: any) {
  return options.drawTicks ? options.tickLength : 0;
}


function getTitleHeight(options: any, fallback: any) {
  if (!options.display) {
    return 0;
  }

  const font = toFont(options.font, fallback);
  const padding = toPadding(options.padding);
  const lines = isArray(options.text) ? options.text.length : 1;

  return (lines * font.lineHeight) + padding.height;
}

function calculateLabelRotation(scale: Scale, tickWidth: number) {
  /* eslint-disable no-underscore-dangle, no-param-reassign */
  const { options } = scale as any;
  const tickOpts = options.ticks;
  const numTicks = getTicksLimit(scale.ticks.length, options.ticks.maxTicksLimit);
  const minRotation = tickOpts.minRotation || 0;
  const { maxRotation } = tickOpts;
  let labelRotation = minRotation;
  let maxHeight;
  let maxLabelDiagonal;

  if (!(scale as any)._isVisible() || !tickOpts.display || minRotation >= maxRotation || numTicks <= 1 || !scale.isHorizontal()) {
    scale.labelRotation = minRotation;
    return;
  }

  const labelSizes = (scale as any)._getLabelSizes();
  const maxLabelWidth = labelSizes.widest.width;
  const maxLabelHeight = labelSizes.highest.height;

  // Allow 3 pixels x2 padding either side for label readability
  if (maxLabelWidth + 6 > tickWidth) {
    maxHeight = scale.maxHeight - getTickMarkLength(options.grid)
      - tickOpts.padding - getTitleHeight(options.title, scale.chart.options.font);
    maxLabelDiagonal = Math.sqrt(maxLabelWidth * maxLabelWidth + maxLabelHeight * maxLabelHeight);
    labelRotation = toDegrees(Math.min(
      Math.asin(_limitValue((labelSizes.highest.height + 6) / tickWidth, -1, 1)),
      Math.asin(_limitValue(maxHeight / maxLabelDiagonal, -1, 1)) - Math.asin(_limitValue(maxLabelHeight / maxLabelDiagonal, -1, 1))
    ));
    labelRotation = Math.max(minRotation, Math.min(maxRotation, labelRotation));
  }

  scale.labelRotation = labelRotation;
  /* eslint-enable no-underscore-dangle, no-param-reassign */
}

const Chart = forwardRef<ChartJS, ChartProps>((props, ref) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { hoveredTrend, setHoveredTrend, trendColors, setTrendColors } = useContext(DashboardContext);
  const { profile } = useContext(SessionContext) as { profile: IProfile };
  const formatCurrency = makeFormatCurrency(profile);
  const formatCurrency2 = makeFormatCurrency(profile, 2);
  const formatPercent = makeFormatPercent(profile);
  const dateFormat = useNoYearDateFormat();
  const {
    data,
    performanceData,
    selectedField,
    dateRange,
    showControl,
    showTreatment,
    dayByDay,
    isBar,
    isStacked,
    isDifference,
    hiddenTrends,
    setHiddenTrends,
    dashboardType,
    dayStepSize,
    campaignData,
  } = props;
  const yTitle = fieldTitleMap[selectedField];
  const isPerformance = selectedField === 'performance';
  const yFormatFn = (() => {
    if (['newNetRevenue', 'grossRevenue'].includes(selectedField) || isPerformance) {
      return formatCurrency;
    }
    if (['aov', 'aovDiff', 'revenuePerVisit', 'revenuePerShopper'].includes(selectedField)) {
      return formatCurrency2;
    }
    return formatPercent;
  })();
  const isTuple = dashboardType === DashboardType.Incremental && (
    selectedField === 'purchaseRate' || selectedField === 'aov'
    || selectedField === 'revenuePerShopper' || selectedField === 'revenuePerVisit'
  );
  const ChartComponent = isBar ? Bar : Line;
  const [pickerActive, setPickerActive] = useState(false);
  const boxWidth = isDesktop ? 40 : 10;
  useEffect(() => {
    ChartJS.defaults.font.size = isDesktop ? 14 : 8;
  }, [isDesktop]);
  const [triggerRerender, setTriggerRerender] = useState(false);
  const prevDayByDay = useRef(dayByDay);
  useEffect(() => {
    if (dayByDay !== prevDayByDay.current) {
      setTriggerRerender(true);
      prevDayByDay.current = dayByDay;
    }
  }, [dayByDay]);
  const prevIsPerformance = useRef(isPerformance);
  useEffect(() => {
    if (isPerformance !== prevIsPerformance.current) {
      setTriggerRerender(true);
      prevIsPerformance.current = isPerformance;
    }
  }, [isPerformance]);
  useEffect(() => {
    if (triggerRerender) {
      setTriggerRerender(false);
    }
  }, [triggerRerender]);
  if (triggerRerender) {
    return null;
  }
  const emptyPoints = (() => {
    if (dayByDay) {
      const points = [];
      const maxDiff = _.max(data.map((trend) => {
        const earliestDate = _.min(trend.points.map((y) => dayjs(y.date).valueOf()));
        const latestDate = _.max(trend.points.map((y) => dayjs(y.date).valueOf()));
        return dayjs(latestDate).diff(earliestDate, 'day');
      })) ?? 0;
      for (let i = 0; i < maxDiff; i += 1) {
        points.push({ x: `${i}`, y: null });
      }
      return points;
    }
    const allPoints = data.flatMap(trend => trend.points);
    const earliestDate = _.min(allPoints.map((y) => dayjs(y.date).valueOf()));
    const latestDate = _.max(allPoints.map((y) => dayjs(y.date).valueOf()));
    const dates = [];
    let currentDate = earliestDate;
    while (dayjs(currentDate).isSameOrBefore(latestDate)) {
      dates.push({ x: dayjs(currentDate).format('YYYY-MM-DD'), y: null });
      currentDate = dayjs(currentDate).add(1, 'day').valueOf();
    }
    return dates;
  })();
  interface Point {
    x: string | number;
    y: number | null;
  }
  const fillEmpty = (points: Point[]): Point[] => emptyPoints.map((emptyPoint) => {
    const point = points.find(({ x }) => x === emptyPoint.x);
    return point ?? emptyPoint;
  });
  const getColor = (baseName: string): TrendColor => (
      baseName === 'Summary'
        ? trendColors.find((x) => 'isSummary' in x)
        : (
          trendColors.find((x) => !('isSummary' in x) && x.offer === baseName)
          || trendColors.find((x) => !('isSummary' in x) && (!x.offer && x.campaign === baseName))
        )
    ) as TrendColor;
  const getTimeData = () => {
    const allDaysData = data.flatMap((trend) => {
      const color = 'isSummary' in trend
        ? trendColors.find((x) => 'isSummary' in x)?.color || ''
        : trendColors.find((x) => !('isSummary' in x) && (x.offer === trend.offer && x.campaign === trend.campaign))?.color || '';
      const trendId = (() => {
        if ('isSummary' in trend) {
          return 'Summary';
        }
        return trend.offer ? `OFFER___${trend.offer}` : trend.campaign;
      })();
      const isHovered = hoveredTrend === trendId;
      const colorWithTransparency = (!hoveredTrend || isHovered) ? color : '#d7d7d7';
      const common = {
        label: 'isSummary' in trend ? 'Summary' : (trend.offer ?? trend.campaign),
        borderColor: colorWithTransparency,
        barPercentage: 0.9,
        categoryPercentage: 0.9,
        borderWidth: 2,
        pointRadius: 2,
        borderJoinStyle: 'round' as any,
        campaign: 'isSummary' in trend ? null : trend.campaign,
        offer: 'isSummary' in trend ? null : trend.offer,
        skipNull: true,
      };
      const earliestDate = _.min(trend.points.map((y) => dayjs(y.date).valueOf()));
      const getX = (point: IChartPoint) => (
        dayByDay ? `${dateRange[0] + dayjs(point.date).diff(dayjs(earliestDate), 'days')}` : point.date
      );
      const getData = (testGroup?: 0 | 1) => {
        let sum = 0;
        const trendData = fillEmpty(trend.points.map((point) => {
          const fieldValue = (point as any)[selectedField];
          const groupValue = testGroup === undefined ? fieldValue : fieldValue[testGroup];
          let y;
          if (isDifference) {
            y = [sum, sum + groupValue ?? 0];
            sum += groupValue ?? 0;
          } else {
            y = groupValue;
          }
          return {
            x: getX(point),
            y,
          };
        }));
        if (isDifference) {
          const differenceColors = trendData.map(({ y }) => (
            (Array.isArray(y) && (y[1] - y[0]) < 0) ? DECREASE_COLOR : INCREASE_COLOR)
          );
          return {
            data: trendData,
            borderColor: differenceColors,
            backgroundColor: differenceColors,
          };
        }
        return { data: trendData };
      }
      if (isTuple) {
        return [
          ...(showControl ? [{
            ...common,
            label: `${common.label} (control)`,
            borderDash: [0.5, 4],
            borderCapStyle: 'round' as any,
            backgroundColor: isBar
              ? createPattern(colorWithTransparency)
              : colorWithTransparency,
            hidden: hiddenTrends[common.label],
            stack: isStacked ? 'control' : undefined,
            ...getData(0),
          }] : []),
          ...(showTreatment ? [{
            ...common,
            label: `${common.label} (treatment)`,
            backgroundColor: colorWithTransparency,
            hidden: hiddenTrends[common.label],
            stack: isStacked ? 'treatment' : undefined,
            ...getData(1),
          }] : []),
        ];
      }
      return [
        {
          ...common,
          backgroundColor: colorWithTransparency,
          hidden: hiddenTrends[common.label],
          stack: isStacked ? 'treatment' : undefined,
          ...getData(),
        },
      ];
    });
    const skipEmpty = (list: any[]) => list.filter((datapoint, i) => (
      allDaysData.some((x) => x.data[i]?.y != null)
    ));
    return allDaysData.map((options) => ({
      ...options,
      data: skipEmpty(options.data),
      ...((isDifference && Array.isArray(options.borderColor) && Array.isArray(options.backgroundColor)) ? {
        borderColor: skipEmpty(options.borderColor),
        backgroundColor: skipEmpty(options.backgroundColor),
      } : {}),
    }));
  };
  const getPerformanceData = () => [
    {
      data: performanceData,
      label: dashboardType === DashboardType.Incremental ? 'Net New Revenue' : 'Gross Revenue',
      backgroundColor: '#199CE5',
    },
  ];
  const isCampaign = campaignData !== undefined;
  const datasets = (() => {
    if (isCampaign) {
      const fieldData = campaignData.find((x) => x.field === selectedField);
      if (!fieldData) {
        return [];
      }
      return [
        {
          data: fieldData.data,
          label: fieldTitleMap[selectedField],
          backgroundColor: fieldData.data.map(({ x }) => getColor(x)?.color),
        },
      ];
    }
    return isPerformance ? getPerformanceData() : getTimeData();
  })();
  const xScale: any = (() => {
    const common = {
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      stacked: isStacked,
    };
    if (isPerformance || isCampaign) {
      return {
        type: 'category',
        ...common,
      };
    }
    return {
      type: 'category',
      ticks: {
        padding: 8,
        stepSize: dayStepSize,
        callback(value: number): string {
          const label = (this as any).getLabelForValue(value as number);
          if (dayByDay) {
            // eslint-disable-next-line react/no-this-in-sfc
            return `Day ${Number(label) + 1}`;
          }
          // eslint-disable-next-line react/no-this-in-sfc
          return dayjs(label).format(dateFormat);
        },
      },
      ...common,
    };
  })();
  const chartLegend: any = (isPerformance || isCampaign) ? {
    display: false,
  } : {
    maxWidth: isDesktop ? 600 : 180,
    position: 'right',
    labels: {
      generateLabels: (chart: any): LegendItem[] => {
        if (isDifference) {
          return [
            {
              text: 'Increase',
              lineDash: [1],
              lineCap: 'round',
              fillStyle: INCREASE_COLOR,
              strokeStyle: INCREASE_COLOR,
              lineWidth: 2,
              fontColor: theme.palette.primary.dark,
            },
            {
              text: 'Decrease',
              lineDash: [1],
              lineCap: 'round',
              fillStyle: DECREASE_COLOR,
              strokeStyle: DECREASE_COLOR,
              lineWidth: 2,
              fontColor: theme.palette.primary.dark,
            },
          ];
        }
        const regularItems = defaults.plugins.legend.labels.generateLabels(chart)
          .filter(({ text }) => (showTreatment && showControl) ? !text.includes('(control)') : true)
          .map((item) => {
            const baseName = item.text.replace(/ \((treatment|control)\)/, '');
            const isHovered = hoveredTrend === baseName;
            return {
              ...item,
              text: baseName,
              fontColor: isHovered ? '#980000' : theme.palette.primary.dark,
            };
          });
        const controlItems: LegendItem[] = [
          {
            text: 'Control',
            lineDash: isBar ? [1] : [0.2, 4],
            lineCap: 'round',
            fillStyle: isBar
              ? createPattern('#757575')
              : 'transparent',
            strokeStyle: '#757575',
            lineWidth: 2,
            fontColor: '#757575',
          },
        ];
        return [
          ...regularItems,
          ...(showControl ? controlItems : []),
        ];
      },
      color: theme.palette.primary.main,
      font: { size: isDesktop ? 12 : 8 },
      boxWidth,
      boxHeight: isBar ? 12 : 0,
    },
    onHover(legend: any, legendItem: any) {
      const trend = legend.chart.data.datasets[legendItem.datasetIndex];
      if (trend) {
        setHoveredTrend(trend.offer ? `OFFER___${trend.offer}` : trend.campaign);
      } else {
        setHoveredTrend(null);
      }
    },
    onLeave() {
      setHoveredTrend(null);
    },
    onClick(event: any, legendItem: any, legend: any) {
      if (legendItem.datasetIndex === undefined) {
        return;
      }
      const leftOffset = (legend.chart.boxes.find((x: any) => 'legendHitBoxes' in x) as any).legendHitBoxes[0].left;
      if ((event.x as number) < leftOffset + boxWidth) {
        const canvasCoords = legend.chart.canvas.getBoundingClientRect();
        const input = document.createElement('input');
        input.type = 'color';
        const current = getColor(legendItem.text);
        input.value = current?.color || '';
        input.style.width = '0';
        input.style.height = '0';
        input.style.visibility = 'hidden';
        input.style.position = 'fixed';
        input.style.left = `${canvasCoords.x + window.scrollX + (event.x || 0)}px`;
        input.style.top = `${canvasCoords.y + window.scrollY + (event.y || 0)}px`;
        input.addEventListener('change', () => {
          setTrendColors([
            ...trendColors.filter((x) => x !== current),
            {
              ...current,
              color: input.value,
            },
          ])
          input.remove();
        });
        document.body.appendChild(input);
        input.click();
        setPickerActive(true);
      } else {
        const index = legendItem.datasetIndex as number;
        const itemMeta = legend.chart.getDatasetMeta(index);
        const controlItem = (showTreatment && showControl && itemMeta.label.includes('(treatment)'))
          ? legend.chart.getDatasetMeta(index - 1)
          : null;
        const name = itemMeta.label.replace(/ \((?:treatment|control)\)$/, '');
        setHiddenTrends((trends) => ({ ...trends, [name]: !legendItem.hidden }));
        if (legendItem.hidden) {
          // eslint-disable-next-line no-param-reassign
          itemMeta.hidden = false;
          if (controlItem) {
            controlItem.hidden = false;
          }
        } else {
          // eslint-disable-next-line no-param-reassign
          itemMeta.hidden = true;
          if (controlItem) {
            controlItem.hidden = true;
          }
        }
        legend.chart.update();
      }
    },
  };
  return (
    <>
      <ChartComponent
        ref={ref as any}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: {
              top: 20,
              bottom: 10,
              left: 10,
              right: 10,
            },
          },
          ['mouseLine' as any]: {
            color: 'rgba(75,87,255,0.3)'
          },
          plugins: {
            legend: chartLegend,
            tooltip: {
              callbacks: {
                title: (items) => {
                  if (isPerformance || isCampaign) {
                    return items[0].label;
                  }
                  return dayByDay ? `${Number(items[0].label) + 1}` : dayjs(items[0].label).format(dateFormat);
                },
                label: (item) => {
                  const { dataset: { label }, parsed: { y }, raw } = item;
                  if (y === null) {
                    return [];
                  }
                  const rawY = (raw as any).y;
                  if (Array.isArray(rawY)) {
                    return `${label}: ${yFormatFn({ value: rawY[1] - rawY[0] })}`;
                  }
                  return `${label}: ${yFormatFn({ value: y })}`;
                },
                footer: (items) => {
                  if (selectedField !== 'purchaseRate') {
                    return '';
                  }
                  const { point } = getPoint(items[0], data, ['purchaseRateChange'])
                  if (point?.purchaseRateChange) {
                    return `Conversion Rate % Change: ${yFormatFn({ value: point.purchaseRateChange })}`;
                  }
                  return '';
                }
              },
              mode: 'customIndex',
              intersect: false,
              position: 'barPositioner' as any,
              xAlign: 'left',
              padding: 16,
              backgroundColor: theme.palette.secondary.dark,
              cornerRadius: 4,
              caretSize: 0,
              caretPadding: 10,
              titleFont: { size: 12 },
              bodyFont: { size: 12 },
              footerFont: { size: 12, weight: '400' },
            },
          },
          interaction: {
            mode: 'customIndex',
            intersect: false,
          },
          scales: {
            y: {
              title: {
                display: true,
                text: yTitle,
                align: 'start',
              },
              ticks: {
                callback: (value) => yFormatFn({ value: value as number }),
              },
              border: {
                display: false,
              },
              grid: {
                lineWidth: ({ tick: { value } }) => value === 0 ? 2 : 1,
                color: ({ tick: { value } }) => value === 0 ? '#5d5d5d' : ChartJS.defaults.borderColor as string,
              },
              stacked: isStacked,
            },
            x: xScale,
          },
        }}
        plugins={[
          {
            id: 'mouseLine',
            /* eslint-disable no-param-reassign, no-underscore-dangle */
            afterEvent(chart: ChartJS, { inChartArea }: any) {
              const active = chart.getActiveElements();
              const gridLineItems = (chart.scales.x as any)._gridLineItems;
              if (inChartArea && active.length && gridLineItems) {
                if (isBar && gridLineItems[active[0].index]) {
                  (chart.options as any).mouseLine.startX = gridLineItems[active[0].index].tx1;
                  (chart.options as any).mouseLine.endX = gridLineItems[active[0].index + 1]?.tx2 ?? chart.chartArea.right;
                } else {
                  (chart.options as any).mouseLine.x = active[0].element.x;
                }
              } else {
                (chart.options as any).mouseLine.startX = undefined;
                (chart.options as any).mouseLine.endX = undefined;
                (chart.options as any).mouseLine.x = undefined;
              }
            },
            /* eslint-enable no-param-reassign, no-underscore-dangle */
            beforeDraw(chart: ChartJS) {
              const { ctx } = chart;
              const { chartArea } = chart;
              const { x, startX, endX, color } = (chart.options as any).mouseLine;

              if (isBar) {
                if (startX !== undefined && endX !== undefined) {
                  ctx.save();
                  ctx.fillStyle = color;
                  ctx.lineWidth = 1
                  ctx.rect(startX, chartArea.top, endX - startX, chartArea.bottom - chartArea.top);
                  ctx.fill();
                  ctx.restore();
                }
              } else if (x !== undefined) {
                ctx.save();
                ctx.strokeStyle = color;
                ctx.lineWidth = 2
                ctx.moveTo(x, chartArea.bottom);
                ctx.lineTo(x, chartArea.top);
                ctx.stroke();
                ctx.restore();
              }
            }
          },
          /* eslint-disable no-param-reassign, no-underscore-dangle */
          {
            id: 'variableLengthCategories',
            beforeDraw(chart) {
              if (isBar && !(chart.scales.y.options as any).stacked && !isCampaign) {
                const BAR_PADDING_FRACTION = 0.1;
                const CATEGORY_PADDING = 12;
                const totalWidth = chart.scales.x.width;
                const metas = chart.getSortedVisibleDatasetMetas();
                const maxLengthMeta = _.maxBy(metas, (x) => x.data.length);
                const tickMetas = (maxLengthMeta?.data ?? []).map((datapoint, tickIndex) => (
                  metas
                    .map((meta) => meta.data[tickIndex])
                ));
                const isNonEmpty = (x: any) => x?.$context.raw.y != null;
                const nonEmpty = (elements: any) => elements.filter((x: any) => isNonEmpty(x));
                const labelItems = chart.scales.x.getLabelItems();
                const categoryCount = tickMetas?.filter((x) => nonEmpty(x).length > 0).length;
                let categoryX = chart.scales.x.left;
                const datapointCount = tickMetas?.flatMap((x) => nonEmpty(x)).length;
                const barPadding = totalWidth / datapointCount * BAR_PADDING_FRACTION;
                const totalPaddings = (categoryCount - 1) * CATEGORY_PADDING + (datapointCount - 1) * barPadding;
                const barWidth = Math.max(0.5, (totalWidth - totalPaddings) / datapointCount);
                const categoryWidths = tickMetas?.map((tickData) => {
                  const nonEmptyLength = nonEmpty(tickData).length;
                  return (
                    nonEmptyLength > 0
                      ? barWidth * nonEmptyLength + barPadding * (nonEmptyLength - 1) + CATEGORY_PADDING
                      : 0
                  );
                });
                const minCategoryWidth = _.min(categoryWidths?.filter((x) => x !== 0)) ?? 0;
                calculateLabelRotation(chart.scales.x, minCategoryWidth);
                tickMetas?.forEach((tickData, tickIndex) => {
                  const gridLineItem = (chart.scales.x as any)._gridLineItems?.[tickIndex];
                  const categoryStart = categoryX - CATEGORY_PADDING / 2;
                  if (gridLineItem) {
                    gridLineItem.tx1 = categoryStart;
                    gridLineItem.tx2 = categoryStart;
                  }
                  const categoryWidth = categoryWidths[tickIndex];
                  const labelItem = labelItems[tickIndex];
                  if (labelItem?.options.translation) {
                    labelItem.options.translation[0] = nonEmpty(tickData).length > 0
                      ? categoryStart + categoryWidth / 2
                      : -9999;
                    labelItem.options.rotation = -toRadians(chart.scales.x.labelRotation);
                  }
                  let datasetIndex = 0;
                  tickData.forEach((datapoint: any) => {
                    if (isNonEmpty(datapoint)) {
                      datapoint.width = barWidth;
                      datapoint.x = categoryX + barWidth / 2 + (barWidth + barPadding) * datasetIndex;
                      datasetIndex += 1;
                    } else {
                      datapoint.skip = true;
                    }
                  });
                  categoryX += categoryWidth;
                });
                labelItems?.splice(tickMetas.length, labelItems.length - tickMetas.length);
              }
            },
          },
          /* eslint-enable no-param-reassign, no-underscore-dangle */
        ]}
        height="300px"
        width="600px"
        data={{
          datasets,
        }}
      />
      <Portal>
        <Backdrop
          sx={{ zIndex: 2000 }}
          open={pickerActive}
          onClick={() => setPickerActive(false)}
        />
      </Portal>
    </>
  );
});

export default Chart;
